import { useContext, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { matchSorter } from 'match-sorter'
import { styled } from '@mui/system'
import {
  Typography,
  TextField,
  Avatar,
  Divider,
  Autocomplete,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import {
  SurveyActions,
  QuestionContainerParent,
} from '@talkadot/survey-component-library'

import { SurveyContext } from '../surveyContext'

const WelcomePageAvatar = styled(Avatar)({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
})

const EventPickerContainer = styled('div')(({ theme }) => ({
  width: '90%',
  padding: '20px',
  borderRadius: theme.shape.borderRadius.xxs,
  backgroundColor: theme.palette.neutral.semiDark,
}))

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
}))

// const sortItemsByCode = (a, b, baseSort) => {
//   if (a.item.code > b.item.code) return 1
//   if (a.item.code < b.item.code) return -1
//   return 0
// }

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, {
    sorter: (matchedItems) => matchedItems,
    // matchedItems.sort((a, b) => sortItemsByCode(a, b, matchedItems)),
    keys: [
      { threshold: matchSorter.rankings.CONTAINS, key: 'talkTitle' },
      { threshold: matchSorter.rankings.STARTS_WITH, key: 'code' },
      {
        threshold: matchSorter.rankings.CONTAINS,
        key: (item) => item.speakers.map((i) => i.full_name),
      },
    ],
  })

const EventPickerAutocomplete = ({ eventList, setSelectedEvent }) => {
  return (
    <StyledAutocomplete
      options={eventList}
      getOptionLabel={(option) => `${option.code} - ${option.talkTitle}`}
      filterOptions={filterOptions}
      onChange={(event, value) => setSelectedEvent(value)}
      renderOption={(props, option, state) => (
        <div key={option.id}>
          <ListItem alignItems="flex-start" {...props}>
            <ListItemIcon
              sx={{
                paddingRight: '25px',
                maxWidth: '58px',
                fontSize: '10px',
                paddingTop: '2px',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <div>{option.startTime}</div>
              <div>{option.sessionDate}</div>
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>
                {option.talkTitle}
              </Typography>
              <Typography sx={{ fontSize: '13px', fontWeight: '300' }}>
                <Highlighter
                  highlightStyle={{
                    fontWeight: '900',
                    backgroundColor: 'inherit',
                  }}
                  searchWords={[state.inputValue]}
                  autoEscape={true}
                  textToHighlight={option.code}
                />
              </Typography>
              <div
                style={{
                  display: 'flex',
                  marginTop: '7px',
                  marginBottom: '7px',
                }}>
                {option.speakers?.map((speaker) => {
                  return (
                    <WelcomePageAvatar
                      key={speaker.id}
                      sx={{ width: 30, height: 30, marginRight: '5px' }}
                      alt={speaker.full_name}
                      src={speaker.avatar || speaker.full_name?.charAt(0)}
                    />
                  )
                })}
              </div>
              <div>
                {option.speakers?.map((speaker) => {
                  return state?.inputValue !== '' &&
                    speaker?.full_name
                      ?.toLowerCase()
                      .includes(state.inputValue.toLowerCase()) ? (
                    <Typography
                      key={speaker.id}
                      sx={{ fontSize: '13px', fontWeight: '300' }}>
                      <Highlighter
                        highlightStyle={{
                          fontWeight: '900',
                          backgroundColor: 'inherit',
                        }}
                        searchWords={[state.inputValue]}
                        autoEscape={true}
                        textToHighlight={speaker.full_name}
                      />
                    </Typography>
                  ) : (
                    <span key={speaker?.id} />
                  )
                })}
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Find Your Session!"
          sx={{ border: 'none' }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}

const EventPlannerWelcomePage = ({ loadTemplate }) => {
  const { surveyState } = useContext(SurveyContext)
  const { eventList } = surveyState
  const [selectedEvent, setSelectedEvent] = useState({})

  return (
    <QuestionContainerParent
      content={`<h1>Which session did you just attend?</h1>
        <br />
        <br />
        <div>Start typing a session name, session code, or presenter name<div>`}>
      <EventPickerContainer>
        <EventPickerAutocomplete
          setSelectedEvent={setSelectedEvent}
          eventList={eventList}
        />
      </EventPickerContainer>
      <SurveyActions
        shouldRenderActions={true}
        disabled={eventList?.length <= 0}
        handleClick={() => loadTemplate(selectedEvent.id)}
        actionText="Let's Start!"
      />
    </QuestionContainerParent>
  )
}

export default EventPlannerWelcomePage
