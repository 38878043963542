import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SurveyParent from '../features/surveys/SurveyParent'
import SurveyComplete from '../features/surveys/SurveyComplete'
import Survey404 from '../features/surveys/Survey404'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/survey-thank-you" component={SurveyComplete} />
      <Route path="/:shortlink" component={SurveyParent} />
      <Route path="/" component={Survey404} />
      <Redirect to={'/'} />
    </Switch>
  );
};

export default Routes;