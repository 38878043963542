import { useContext } from 'react'
import { SurveyContext } from './surveyContext'
import { LinkPossibleAnswerTemplate } from '@talkadot/survey-component-library'

const OptOutLinkPossibleAnswer = ({ possibleAnswer }) => {
  const { submitBooleanAnswer } = useContext(SurveyContext)

  return (
    <LinkPossibleAnswerTemplate handleClick={() => submitBooleanAnswer(possibleAnswer)}
                                possibleAnswer={possibleAnswer} />
  )
}

export default OptOutLinkPossibleAnswer
