import { useContext, useState, useEffect } from 'react'
import { SurveyContext } from './surveyContext'
import { BooleanPossibleAnswerTemplate } from '@talkadot/survey-component-library'

const BooleanPossibleAnswer = ({ possibleAnswer }) => {
  const [selected, setSelected] = useState(false)
  const { submitBooleanAnswer } = useContext(SurveyContext)

  useEffect(() => {
    if (possibleAnswer.answerValue) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [possibleAnswer.id])

  const handleClick = () => {
    submitBooleanAnswer(possibleAnswer)
  }

  return (
    <BooleanPossibleAnswerTemplate
      selected={selected}
      handleClick={handleClick}
      content={possibleAnswer.content}
    />
  )
}

export default BooleanPossibleAnswer
