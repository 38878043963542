import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const Footer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  margin: '0 auto',
})

const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
}))

const FooterLinkWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
})

const FooterLink = styled('a')({
  textDecoration: 'none',
  img: {
    maxWidth: '84px',
  },
})

const SurveyFooter = () => {
  return (
    <Footer>
      <FooterText variant="caption">Powered by</FooterText>
      <FooterLinkWrapper>
        <FooterLink
          href="https://talkadot.com"
          target="_blank"
          rel="noreferrer">
          <img
            src="https://d3qvkz8495pjoq.cloudfront.net/talkadot-logo.png"
            alt="Talkadot!"
          />
        </FooterLink>
      </FooterLinkWrapper>
    </Footer>
  )
}

export default SurveyFooter
