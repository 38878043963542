import 'whatwg-fetch'
import * as qs from 'qs'
import envConfig from '../envConfig'

const API_ROOT = envConfig.REACT_APP_API_ENDPOINT

async function makeRequest(path, method, data = {}) {
  const url = API_ROOT + path

  const headers = {
    'Content-Type': 'application/json',
  }

  try {
    let res

    if (method === 'post' || method === 'put' || method === 'delete') {
      res = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(data),
      })
    } else {
      res = await fetch(url, {
        method,
        headers,
      })
    }

    const json = await res.json()

    if (res.status === 401) {
      return window.location.reload()
    }

    if (res.status === 200) {
      return json.data
    }

    return json
  } catch (err) {
    return {
      errors: err,
    }
  }
}

// Modifies the fetch function by: Adding access token and/or changing HTTP method
function get(path, data) {
  return makeRequest(path, 'get', data)
}

function post(path, data) {
  return makeRequest(path, 'post', data)
}

function put(path, data) {
  return makeRequest(path, 'put', data)
}

function destroy(path, data) {
  return makeRequest(path, 'delete', data)
}

export default {
  loadSurvey(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/survey?${queryParams}`)
  },

  createAnswer(data) {
    return post('/answers', data)
  },

  createInitialLead(data) {
    return post('/leads', data)
  },

  getSurveyOfferByCode(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/submissions/complete?${queryParams}`)
  },

  getCustomQuestions(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/custom_questions?${queryParams}`)
  },

  createIntegrationSync(data) {
    return post('/integration_syncs', data)
  },

  fetchTemplate(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/event_planner/surveys?${queryParams}`)
  },

  fetchSurveyConfiguration(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/survey/load-configuration?${queryParams}`)
  },

  validateCode(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/survey/validate-code?${queryParams}`)
  },
}
