import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import { ReactComponent as CatPic } from '../../icons/404cat.svg'

import { SurveyContainer } from '@talkadot/survey-component-library'
import SurveyFooter from './SurveyFooter'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1.5rem',
  width: '75%',
  height: '100%',
})

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))

const Survey404 = () => {
  return (
    <SurveyContainer>
      <Container>
        <Header variant="body2">
          Oh no! We bet this isn't the page you are looking for.
        </Header>
        <CatPic />
        <Typography variant="body2">
          Please double check the feedback link with the speaker, or try
          scanning the QR code again.
        </Typography>
      </Container>
      <SurveyFooter />
    </SurveyContainer>
  )
}

export default Survey404
