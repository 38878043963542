// returns isValid: boolean, error: string
export const validateInput = (answerType, valueRequired, value) => {
  if (!value || value === '') {
    if (!valueRequired) {
      // when the value is not required, it is considered valid
      return {
        isRequirementSatisfied: true,
      }
    } else {
      return {
        isRequirementSatisfied: false,
        message: `${
          answerType.charAt(0).toUpperCase() +
          answerType.slice(1).toLowerCase().split('_').join(' ')
        } is required`,
      }
    }
  }

  // All other input types are considered valid if they are not empty
  if (answerType !== 'EMAIL') {
    return {
      isRequirementSatisfied: true,
    }
  }

  const { valid, message } = isValidEmail(value)

  return {
    isRequirementSatisfied: valid,
    message: message,
  }
}

// Regex used is the same as our backend
export const isValidEmail = (email) => {
  // test cases @, @@, @@@

  if (!/^([^@]*@[^@]*)$/.test(email)) {
    return { valid: false, message: 'An email address must contain a single @' }
  }

  const [username, domain] = email.split('@')

  if (username.length < 1) {
    return {
      valid: false,
      message:
        'The username portion of the email address is missing (the portion before the @:)',
    }
  }

  const username_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+$/

  if (!username_regex.test(username)) {
    return {
      valid: false,
      message:
        'The username portion of the email address contains an invalid character (the portion before the @:)',
    }
  }

  if (domain.length < 1) {
    return {
      valid: false,
      message:
        'The domain portion of the email address is missing (the portion after the @:)',
    }
  }

  const domainRegex =
    /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

  if (!domainRegex.test(domain)) {
    return {
      valid: false,
      message:
        'The domain portion of the email address is invalid (the portion after the @)',
    }
  }

  return { valid: true }
}
