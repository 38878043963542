import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Survey from './surveyHelpers'
import { SurveyContext } from './surveyContext'
import Api from '../../services/api'
import {
  trackFreebieDownload,
  trackBadCode,
  trackHitMeetingLink,
} from '../../services/metrics'
import { isMobileOnly } from 'react-device-detect'

import {
  SurveyContainer,
  SurveyHeader,
  ThankYouParent,
} from '@talkadot/survey-component-library'
import SurveyFooter from './SurveyFooter'
import { SURVEY_HEIGHT_THRESHOLD } from './SurveyParent'

const SurveyComplete = () => {
  const [offerUrl, setOfferUrl] = useState('')
  const [offerError, setOfferError] = useState(false)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const { surveyState, clearSurvey } = useContext(SurveyContext)
  const {
    socialLinks,
    shortlink,
    name,
    showSurveyOfferQuestions,
    bookingLink,
    canShowBookingLink,
    hideFreebieLink,
  } = surveyState.speaker

  const history = useHistory()

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // If the user didnt just fill out a survey,
  // redirect them back to the homepage or something
  useEffect(() => {
    const getThankYouOffer = async () => {
      try {
        const offerCode = Survey.findOfferCode(surveyState)

        // Protect from erroring out
        if (!offerCode) {
          return
        }

        const params = {
          submission: {
            id: surveyState.submissionId,
            shortlink: shortlink,
            offer_code: offerCode.toUpperCase(),
          },
        }

        const res = await Api.getSurveyOfferByCode(params)

        if (!res.errors) {
          setOfferUrl(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        trackBadCode({
          speaker: name,
        })
        // If this is an event planner thank you, don't set offer error
        if (!surveyState.event.id) {
          setOfferError(true)
        }
      }
    }

    if (!surveyState.id) {
      return history.push('/')
    } else {
      if (showSurveyOfferQuestions && !hideFreebieLink) {
        getThankYouOffer()
      }
    }

    return () => {
      clearSurvey()
    }
  }, [])

  const showBookingLink =
    canShowBookingLink && bookingLink && Survey.isLeadQualified(surveyState)

  const handleSurveyOfferDownload = () => {
    trackFreebieDownload({ speaker: name })
    const regex = /^((http|https):\/\/)/
    const url = !regex.test(offerUrl) ? '//' + offerUrl : offerUrl
    return window.open(url, '_blank').focus()
  }

  const handleMeetingLinkClick = () => {
    trackHitMeetingLink({ speaker: name })
    return window.open(bookingLink, '_blank').focus()
  }

  const bonusLinkText = surveyState?.eventGroup?.thank_you_button_text
    ? surveyState.eventGroup.thank_you_button_text
    : surveyState?.speaker?.isCeProvider
    ? 'Get Your CE Certificate'
    : 'Get Your Bonus'

  const renderThankYouText = () => {
    // For a global event planner survey, show custom thank you text
    // If not a global planner survey, show event text
    // If not an event, show normal text

    if (!surveyState.event.id) {
      return 'All Done! Thank you so much! 😁'
    }

    if (
      surveyState.event?.event_type?.toUpperCase()?.includes('GLOBAL') &&
      surveyState?.eventGroup?.thank_you_message
    ) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: surveyState.eventGroup.thank_you_message,
          }}
        />
      )
    }

    return 'Feedback sent!'
  }

  const emptySocials =
    !socialLinks?.instagram &&
    !socialLinks?.twitter &&
    !socialLinks?.linkedin &&
    !socialLinks?.youtube

  const surveyHeight =
    isMobileOnly || screenHeight < SURVEY_HEIGHT_THRESHOLD
      ? '100%'
      : SURVEY_HEIGHT_THRESHOLD

  return (
    <SurveyContainer
      isTestMode={Survey.isTestMode()}
      showBorder={!isMobileOnly}
      height={surveyHeight}>
      <SurveyHeader survey={surveyState} />
      <ThankYouParent
        thankYouText={renderThankYouText()}
        speakerName={name}
        showBookingLink={showBookingLink}
        showBonusLink={offerUrl}
        handleBookingLinkClick={handleMeetingLinkClick}
        handleBonusLinkClick={handleSurveyOfferDownload}
        bonusLinkText={bonusLinkText}
        bookingLinkText="Book A Meeting"
        socialLinks={socialLinks}
        showSocialLinks={!surveyState.event.id && !emptySocials}
        showOfferError={offerError}
      />
      <SurveyFooter />
    </SurveyContainer>
  )
}

export default SurveyComplete
