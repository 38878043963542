import * as ActionTypes from './actionTypes'

export const initialAuthState = {
  // I know this is hacky ...
  // Registration Info
  tosChecked: false,
  privacyChecked: false,
  // User Info
  user: {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
    avatar: null,
    onboarding_complete: null,
    shortlink: null,
    socialLinks: {
      instagram: null,
      twitter: null,
      youtube: null,
      linkedin: null
    },
  },
  // Global Info
  fullPageLoader: {
    isActive: false,
    content: null,
    loaderType: null
  },
  notification: {
    message: null,
    notificationType: 'info',
    showNotification: false,
  }
}

export const authenticationReducer = (state, action) => {
  switch(action.type) {
    case ActionTypes.SET_USER_ATTRIBUTE:
      return {
        ...state,
        user: { 
          ...state.user,
          [action.payload.attributeType]: action.payload.value
        }
      }
    case ActionTypes.SET_SOCIAL_MEDIA_LINK:
      return {
        ...state,
        user: {
          ...state.user,
          socialLinks: {
            ...state.user.socialLinks,
            [action.payload.attributeType]: action.payload.value
          }
        }
      }
    case ActionTypes.SET_AUTH_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case ActionTypes.ADD_INDUSTRY:
      return {
        ...state,
        user: {
          ...state.user,
          industries: [...state.user.industries, action.payload]
        }
      } 
    case ActionTypes.REMOVE_INDUSTRY:
      return {
        ...state,
        user: {
          ...state.user,
          industries: state.user.industries.filter((industry) => industry !== action.payload)
        }
      }
    case ActionTypes.TOGGLE_TOS:
      return {
        ...state,
        tosChecked: !state.tosChecked
      }
    case ActionTypes.TOGGLE_PRIVACY:
      return {
        ...state,
        privacyChecked: !state.privacyChecked
      }
    case ActionTypes.RESET_AUTH_DATA:
      return {
        ...initialAuthState,
      }
    case ActionTypes.SHOW_FULL_PAGE_LOADER:
      return {
        ...state,
        fullPageLoader: {
          ...state.fullPageLoader,
          isActive: true,
          content: action.payload.content,
          loaderType: action.payload.loaderType
        }
      }
    case ActionTypes.HIDE_FULL_PAGE_LOADER:
      return {
        ...state,
        fullPageLoader: {
          ...state.fullPageLoader,
          isActive: false,
          content: null
        }
      }
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          ...action.payload
        }
      }
    default: 
      return { ...state }
  }
}