import { useContext } from 'react'
import { SurveyContext } from './surveyContext'
import { EmojiScalePossibleAnswerTemplate } from '@talkadot/survey-component-library'

const EmojiScalePossibleAnswer = ({ possibleAnswer }) => {
  const { submitEmojiScaleAnswer } = useContext(SurveyContext)

  const handleClick = (e, emoji) => {
    submitEmojiScaleAnswer(possibleAnswer, emoji.value)
  }

  return (
    <EmojiScalePossibleAnswerTemplate
      handleClick={handleClick}
      answerValue={possibleAnswer?.answerValue}
    />
  )
}

export default EmojiScalePossibleAnswer
