export const SET_USER_ATTRIBUTE = 'SET_USER_ATTRIBUTE'
export const SET_SOCIAL_MEDIA_LINK = 'SET_SOCIAL_MEDIA_LINK'
export const SET_AUTH_DATA = 'SET_AUTH_DATA'
export const RESET_AUTH_DATA = 'RESET_AUTH_DATA'
export const ADD_INDUSTRY = 'ADD_INDUSTRY'
export const REMOVE_INDUSTRY = 'REMOVE_INDUSTRY'
export const TOGGLE_TOS = 'TOGGLE_TOS'
export const TOGGLE_PRIVACY = 'TOGGLE_PRIVACY'

export const SHOW_FULL_PAGE_LOADER = 'SHOW_FULL_PAGE_LOADER'
export const HIDE_FULL_PAGE_LOADER = 'HIDE_FULL_PAGE_LOADER'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
