import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography'

import './FullPageLoader.scss'

const FullPageLoader = ({content, loaderType}) => {
  return(
    <div className={`loader__wrapper ${loaderType && loaderType}`}>
      <div className="loader__graphic">
        <div>
          <CircularProgress color="inherit" sx={{width: '60px !important', height: '60px !important'}} />
        </div>
        <Typography component="div" 
                    variant="h6"
                    sx={{marginTop: '15px', color: 'white', fontWeight: '600'}}>
          {content}  
        </Typography>   
      </div>
    </div>
  )
}

export default FullPageLoader