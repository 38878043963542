import React, { useContext } from 'react'
import FullPageLoader from './features/common/FullPageLoader'

import './App.scss'

import Routes from './routes/index'

import { AuthenticationContext } from './features/authentication/authenticationContext'

function App() {
  const { authData } = useContext(AuthenticationContext)
  const { fullPageLoader, notification } = authData

  return (
    <div className="App">
      <div className="unauth-container">
        <Routes />
      </div>
      {fullPageLoader && fullPageLoader.isActive && (
        <FullPageLoader
          content={fullPageLoader.content}
          loaderType={fullPageLoader.loaderType}
        />
      )}
    </div>
  )
}

export default App
