import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
// import { ThemeProvider, createTheme } from '@mui/material/styles'

import App from './App'
import reportWebVitals from './reportWebVitals'
import history from './routes/history'
import AuthenticationContextProvider from './features/authentication/authenticationContext'
import SurveyContextProvider from './features/surveys/surveyContext'

import './index.css'
// import { colors, borderRadius, boxShadow } from './styles/styleGuide'

// Error Logging
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ThemeProviderWrapper } from '@talkadot/survey-component-library'

Sentry.init({
  dsn: 'https://e9ac63ff824742ebb26a43fc6a0ad2ef@o1153581.ingest.sentry.io/6232787',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.0,
})

// // Font weights are defined here instead of styleGuide.js because in designs it depend on both typography and weight type
// const fontWeights = {
//   regular: 400,
//   semiBold: 600,
//   bold: 700,
//   extraBold: 800,
// }

// const typography = {
//   paragraph01: {
//     fontFamily: 'Figtree, sans-serif',
//     fontSize: '0.875rem',
//     fontWeight: 400,
//     lineHeight: 1.43,
//     color: colors.neutral[900],
//   },
// }

// const theme = createTheme({
//   typography: {
//     fontFamily: 'Figtree, sans-serif',
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           textTransform: 'none',
//           borderRadius: borderRadius.sm,
//           padding: '12px 20px',
//           boxShadow: 'none',
//           fontSize: '1.25rem',
//           fontWeight: 600,
//         },
//       },
//     },
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           '& .MuiOutlinedInput-notchedOutline': {
//             borderColor: colors.neutral[200],
//           },
//           borderRadius: borderRadius.xxs,
//         },
//       },
//     },
//     MuiAutocomplete: {
//       styleOverrides: {
//         listbox: {
//           '& .MuiListItem-root': {
//             paddingTop: '5px',
//             paddingBottom: '5px',
//             alignItems: 'flex-start',
//             '& .MuiListItemIcon-root': {
//               paddingRight: '7px',
//               marginTop: '5px',
//             },
//           },
//         },
//       },
//     },
//   },
//   palette: {
//     primary: {
//       lightest: colors.primaryOrange[50],
//       extraLight: colors.primaryOrange[100],
//       veryLight: colors.primaryOrange[200],
//       light: colors.primaryOrange[300],
//       semiLight: colors.primaryOrange[400],
//       main: colors.primaryOrange[500],
//       semiDark: colors.primaryOrange[600],
//       dark: colors.primaryOrange[700],
//       veryDark: colors.primaryOrange[800],
//       darkest: colors.primaryOrange[900],
//     },
//     secondary: {
//       lightest: colors.primaryBlue[50],
//       extraLight: colors.primaryBlue[100],
//       veryLight: colors.primaryBlue[200],
//       light: colors.primaryBlue[300],
//       semiLight: colors.primaryBlue[400],
//       main: colors.primaryBlue[500],
//       semiDark: colors.primaryBlue[600],
//       dark: colors.primaryBlue[700],
//       veryDark: colors.primaryBlue[800],
//       darkest: colors.primaryBlue[900],
//     },
//     tertiary: {
//       main: colors.neutral[700],
//     },
//     neutral: {
//       lightest: colors.neutral[50],
//       extraLight: colors.neutral[100],
//       veryLight: colors.neutral[200],
//       light: colors.neutral[300],
//       semiLight: colors.neutral[400],
//       main: colors.neutral[500],
//       semiDark: colors.neutral[600],
//       dark: colors.neutral[700],
//       veryDark: colors.neutral[800],
//       darkest: colors.neutral[900],
//     },
//     base: {
//       white: colors.shades[0],
//       black: colors.shades[100],
//     },
//     border: {
//       lighter: colors.neutral[50],
//       light: colors.neutral[100],
//       main: colors.neutral[200],
//     },
//     surface: {
//       subtle: colors.neutral[50],
//     },
//     text: {
//       navText: colors.neutral[600],
//       disabled: colors.neutral[400],
//       inputText: colors.neutral[800],
//     },
//     table: {
//       selectedRowPrimary: colors.accentYellow[200],
//       selectedRowSecondary: colors.accentYellow[100],
//       hoverRow: colors.neutral[50],
//     },
//     success: {
//       lightest: colors.success[50],
//       extraLight: colors.success[100],
//       veryLight: colors.success[200],
//       light: colors.success[300],
//       semiLight: colors.success[400],
//       main: colors.success[500],
//       semiDark: colors.success[600],
//       dark: colors.success[700],
//       veryDark: colors.success[800],
//       darkest: colors.success[900],
//     },
//     error: {
//       lightest: colors.error[50],
//       extraLight: colors.error[100],
//       veryLight: colors.error[200],
//       light: colors.error[300],
//       semiLight: colors.error[400],
//       main: colors.error[500],
//       semiDark: colors.error[600],
//       dark: colors.error[700],
//       veryDark: colors.error[800],
//       darkest: colors.error[900],
//     },
//     purple: {
//       lightest: colors.tags.purple[50],
//       main: colors.tags.purple[500],
//     },
//     pink: {
//       lightest: colors.tags.pink[50],
//       main: colors.tags.pink[500],
//     },
//     nav: {
//       main: colors.neutral[200],
//     },
//   },
//   shape: {
//     borderRadius: {
//       ...borderRadius,
//     },
//     boxShadow: {
//       ...boxShadow,
//     },
//   },
//   fontWeights,
//   // palette: {
//   //   primary: {
//   //     main: '#ff9900',
//   //   },
//   //   secondary: {
//   //     main: '#1799cb',
//   //     light: 'skyblue',
//   //     extraLight: 'rgb(17, 151, 202, 0.2)',
//   //   },
//   //   tertiary: {
//   //     light: '#DDDDDC',
//   //     main: '#333333',
//   //     extraLight: '#F5F5F5',
//   //     contrastText: 'rgba(255, 255, 255, 0.87)',
//   //     disabledText: 'rgba(0, 0, 0, 0.52)',
//   //   },
//   //   nav: {
//   //     main: '#CCCCCC',
//   //   },
//   //   base: {
//   //     white: '#FFFFFF',
//   //     black: '#000000',
//   //   },
//   // },
// })

// theme.typography.display1 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '4.5rem',
//   fontWeight: 600,
//   lineHeight: 1.11,
//   letterSpacing: '-0.04em',
//   color: colors.neutral[900],
// }

// theme.typography.display2 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '3.75rem',
//   fontWeight: 600,
//   lineHeight: 1.2,
//   letterSpacing: '-0.04em',
//   color: colors.neutral[900],
// }

// theme.typography.h1 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '3rem',
//   fontWeight: 600,
//   lineHeight: 1.17,
//   letterSpacing: '-0.04em',
//   color: colors.neutral[900],
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '2.125rem',
//   },
// }

// theme.typography.h2 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '2.4375rem',
//   fontWeight: 600,
//   lineHeight: 1.2,
//   letterSpacing: '-0.02em',
//   color: colors.neutral[900],
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '2.0625rem',
//   },
// }

// theme.typography.h3 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '2rem',
//   fontWeight: 600,
//   lineHeight: 1.125,
//   letterSpacing: '-0.02em',
//   color: colors.neutral[900],
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.75rem',
//   },
// }

// theme.typography.h4 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '1.75rem',
//   fontWeight: 600,
//   lineHeight: 1.214,
//   color: colors.neutral[900],
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.4375rem',
//   },
// }

// theme.typography.h5 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '1.375rem',
//   fontWeight: 700,
//   lineHeight: 1.36,
//   letterSpacing: '-0.02em',
//   color: colors.neutral[900],
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.125rem',
//   },
// }

// theme.typography.h6 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '1.125rem',
//   fontWeight: 700,
//   lineHeight: 1.28,
//   letterSpacing: '-0.02em',
//   color: colors.neutral[900],
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1rem',
//   },
// }

// // Subheading in the style guide
// theme.typography.subtitle1 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '1.25rem',
//   fontWeight: 400,
//   lineHeight: 1.2,
//   color: colors.neutral[900],
// }

// // Paragraph 01 in the style guide
// theme.typography.body1 = {
//   ...typography.paragraph01,
// }

// // Paragraph 02 in the style guide
// theme.typography.body2 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '1rem',
//   fontWeight: 400,
//   lineHeight: 1.1875,
//   color: colors.neutral[900],
// }

// theme.typography.paragraph00 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '0.75rem',
//   fontWeight: 400,
//   lineHeight: 1.17,
//   color: colors.neutral[800],
// }

// theme.typography.paragraph03 = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '1.125rem',
//   fontWeight: 400,
//   lineHeight: 1.22,
//   color: colors.neutral[900],
// }

// theme.typography.conversation = {
//   fontFamily: 'CaveatBrush, cursive',
//   fontSize: '1.125rem',
//   fontWeight: 400,
//   lineHeight: 1.2,
//   color: colors.neutral[900],
// }

// theme.typography.caption = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '0.75rem',
//   fontWeight: 400,
//   lineHeight: 1.17,
//   color: colors.neutral[900],
// }

// theme.typography.footer = {
//   fontFamily: 'Figtree, sans-serif',
//   fontSize: '0.833rem',
//   fontWeight: 400,
//   lineHeight: 1.2,
//   color: colors.neutral[900],
// }

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <AuthenticationContextProvider>
        <SurveyContextProvider>
          <ThemeProviderWrapper>
            <App />
          </ThemeProviderWrapper>
        </SurveyContextProvider>
      </AuthenticationContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
