import {
  useContext,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'

import { SurveyContext } from './surveyContext'
import * as Survey from './surveyHelpers'

import { validateInput } from '../../utils/validator'
import { invalidKeyPress } from '../../utils/keyPress'

import { SimpleTextPossibleAnswerTemplate } from '@talkadot/survey-component-library'

const SimpleTextPossibleAnswer = forwardRef(({ possibleAnswer }, ref) => {
  const { updateAnswerValue, surveyState } = useContext(SurveyContext)
  const [error, setError] = useState(null)
  const [hasBlurred, setHasBlurred] = useState(false)

  useEffect(() => {
    if (possibleAnswer.answerType === Survey.ANSWER_TYPES.EMAIL) {
      const email = Survey.findEmail(surveyState)
      if (email) {
        updateAnswerValue(possibleAnswer, email)
      }
    }

    if (possibleAnswer.answerType === Survey.ANSWER_TYPES.NAME) {
      const name = Survey.findName(surveyState)
      if (name) {
        updateAnswerValue(possibleAnswer, name)
      }
    }

    // Solve bug where error states were persisting across multiple inputs
    setError(null)
  }, [possibleAnswer.id])

  const { answerType, answerValue, helperText, inputRequired } = possibleAnswer

  const handleChange = (e) => {
    if (invalidKeyPress(e, answerType)) {
      e.preventDefault()
      return
    }

    const inputValue = e.target.value

    updateAnswerValue(possibleAnswer, inputValue)

    // If they have have blurred previously validate right away
    if (hasBlurred) {
      setError(null)
      validateValue(inputValue)
    }
  }

  useImperativeHandle(ref, () => ({
    forceValidation,
  }))

  const forceValidation = () => {
    if (possibleAnswer.inputRequired !== true) return 'valid'

    const isRequirementSatisfied = validateValue(answerValue)

    setHasBlurred(true)

    return isRequirementSatisfied
  }

  const validateValue = (inputValue) => {
    const { message, isRequirementSatisfied } = validateInput(
      answerType,
      inputRequired,
      inputValue
    )

    if (message) {
      setError(message)
    }

    return isRequirementSatisfied
  }

  const handleBlur = () => {
    setHasBlurred(true)
    validateValue(answerValue) // referencing possibleAnswer does not work
  }

  const placeholder = helperText
    ? helperText.toLowerCase()
    : answerType.toLowerCase().split('_').join(' ')

  return (
    <SimpleTextPossibleAnswerTemplate
      fieldValue={answerValue || ''}
      possibleAnswer={possibleAnswer}
      handleChange={handleChange}
      error={error}
      handleBlur={handleBlur}
      placeholder={Survey.capitalizeFirstLetter(placeholder)}
    />
  )
})

export default SimpleTextPossibleAnswer
