const BLOCKED_CHARACTERS = new Set([
  ' ',
  '(',
  ')',
  '<',
  '>',
  '[',
  '\\',
  ']',
  '`',
  ':',
  ';',
  '"',
  ',',
])

export const invalidKeyPress = (event, answerType) => {
  if (answerType !== 'EMAIL') {
    return false
  }

  const keyPress = event.nativeEvent.data

  return BLOCKED_CHARACTERS.has(keyPress)
}
