import { useContext, useState, useEffect } from 'react'

import {
  SurveyActions,
  QuestionContainerParent,
  QuestionContainer,
  QuestionContainerInner,
  CodeInputTemplate,
} from '@talkadot/survey-component-library'

import { SurveyContext } from './surveyContext'
import Api from '../../services/api'

const SpeakerWelcomePage = ({
  loadSurveyConfiguration,
  loadCustomQuestions,
  shortlink,
}) => {
  const { surveyState, setSurvey, updateConfigurationCode } =
    useContext(SurveyContext)
  const [isLoading, setIsLoading] = useState(false)
  const [invalidCode, setInvalidCode] = useState(false)
  const { configurationCode, codeValid } = surveyState

  useEffect(() => {
    // Automatically start the survey if the code exists and is valid
    if (codeValid && configurationCode) {
      handleSurveyStart()
    }
  }, [codeValid, configurationCode])

  const validateCode = async () => {
    setIsLoading(true)

    const reqParams = {
      survey: {
        shortlink,
        offer_code: configurationCode,
      },
    }

    try {
      const res = await Api.validateCode(reqParams)

      if (!res.errors) {
        setIsLoading(false)

        if (res.codeValid) {
          setSurvey({ ...surveyState, codeValid: true })
        } else {
          setInvalidCode(true)
        }
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsLoading(false)
      return false
    }

    setIsLoading(false)
  }

  const handleSurveyStart = async () => {
    setIsLoading(true)
    await loadSurveyConfiguration({ shortlink, code: configurationCode })
    await loadCustomQuestions({ shortlink, code: configurationCode })
  }

  // if the code is in the url and it's valid, but the configuration hasn't loaded yet
  // don't show the welcome page, show the loading page
  if (codeValid && configurationCode && !surveyState.configurationLoaded) {
    return (
      <QuestionContainer>
        <QuestionContainerInner />
      </QuestionContainer>
    )
  }

  return (
    <QuestionContainerParent
      content={`<h1>Hi! &#128075; Thanks for your time!</h1>
        <br />
        <br />
        <div>We promise, this is quick.<div>`}
      contentAlign="center">
      <CodeInputTemplate
        value={configurationCode}
        handleChange={(e) =>
          updateConfigurationCode(e.target.value?.toUpperCase())
        }
        speakerName={surveyState.speaker.name}
        invalidValue={invalidCode}
      />
      <SurveyActions
        shouldRenderActions={true}
        disabled={isLoading}
        handleClick={validateCode}
        actionText={invalidCode ? 'Try Again' : "Let's Start!"}
      />
    </QuestionContainerParent>
  )
}

export default SpeakerWelcomePage
