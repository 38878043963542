import { useContext, useState, useEffect } from 'react'
import { SurveyContext } from './surveyContext'
import { CheckboxPossibleAnswerTemplate } from '@talkadot/survey-component-library'

const CheckboxPossibleAnswer = ({ possibleAnswer }) => {
  const [checked, setChecked] = useState(true)
  const { updateAnswerValue } = useContext(SurveyContext)

  useEffect(() => {
    if (
      possibleAnswer.answerValue !== undefined &&
      possibleAnswer.answerValue !== null
    ) {
      setChecked(possibleAnswer.answerValue)
    } else {
      // Update answerValue to reflect the initial state of the checkbox
      updateAnswerValue(possibleAnswer, checked)
    }
  }, [possibleAnswer.answerValue])

  const handleUpdate = (e) => {
    updateAnswerValue(possibleAnswer, e.target.checked)
    setChecked(e.target.checked)
  }

  return (
    <CheckboxPossibleAnswerTemplate
      checked={checked}
      handleChange={handleUpdate}
      content={possibleAnswer.content}
    />
  )
}

export default CheckboxPossibleAnswer
