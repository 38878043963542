function gtag(){window.dataLayer.push(arguments);}

const trackEventWithGoogle = (eventName, params) => {
  try {
    gtag('event', eventName, params);
  } catch(err) {
    // handle errors
  }
}

export const trackFreebieDownload = (params) => {
  return trackEventWithGoogle('downloaded_freebie', params)
}

export const trackBadCode = (params) => {
  return trackEventWithGoogle('bad_code', params)
}

export const trackEmptyCode = (params) => {
  return trackEventWithGoogle('empty_code', params)
}

export const trackHitMeetingLink = (params) => {
  return trackEventWithGoogle('hit_meeting_link', params)
}