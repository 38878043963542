import { useEffect, useContext, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import * as qs from 'qs'
import * as Sentry from '@sentry/react'

import QuestionParent from './QuestionParent'
import SurveyFooter from './SurveyFooter'
import SpeakerWelcomePage from './SpeakerWelcomePage'

import EventPlannerWelcomePage from './eventPlanner/EventPlannerWelcomePage'

import { SurveyContext } from './surveyContext'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { isMobileOnly } from 'react-device-detect'

import * as Survey from './surveyHelpers'
import Api from '../../services/api'

import {
  SurveyContainer,
  SurveyHeader,
} from '@talkadot/survey-component-library'

export const SURVEY_HEIGHT_THRESHOLD = 800

const SurveyParent = () => {
  const [urlCodeValid, setUrlCodeValid] = useState(false)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)

  let params = useParams()
  const history = useHistory()
  const location = useLocation()

  const { surveyState, setSurvey, setCustomQuestion, updateConfigurationCode } =
    useContext(SurveyContext)
  const { showFullPageLoader, hideFullPageLoader, setNotification } =
    useContext(AuthenticationContext)

  const question = Survey.findCurrentQuestion(surveyState)
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const loadSurvey = async () => {
      showFullPageLoader('Just a sec ... loading feedback form')

      const reqParams = {
        survey: {
          shortlink: params.shortlink,
          offer_code: urlParams.code,
        },
      }

      // If we're viewing the survey in test mode,
      // it's important we send that info to the API
      Survey.isTestMode() &&
        Object.assign(reqParams.survey, { test_mode: true })

      try {
        // Fetches speaker data and codeValid boolean
        const res = await Api.loadSurvey(reqParams)

        if (!res.errors) {
          setSurvey(res)

          if (res.codeValid) {
            setUrlCodeValid(true)
          }

          hideFullPageLoader()
          return
        } else {
          throw res.errors
        }
      } catch (err) {
        hideFullPageLoader()
        setNotification(JSON.stringify(err))
        return history.push('/')
      }
    }

    loadSurvey()
  }, [])

  useEffect(() => {
    // This triggers after the initial loadSurvey data is returned with codeValid set to true
    if (urlCodeValid) {
      setSurvey({ ...surveyState, codeInUrl: true })
      updateConfigurationCode(urlParams.code)
    }
  }, [urlCodeValid])

  const loadSurveyConfiguration = async ({ shortlink, code }) => {
    showFullPageLoader('', 'light')
    const reqParams = {
      survey: {
        shortlink: shortlink,
        offer_code: code,
      },
    }

    // If we're viewing the survey in test mode,
    // it's important we send that info to the API
    Survey.isTestMode() && Object.assign(reqParams.survey, { test_mode: true })

    try {
      const res = await Api.fetchSurveyConfiguration(reqParams)

      if (!res.errors) {
        setSurvey(res)
        hideFullPageLoader()
        return
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      setNotification(JSON.stringify(err))
      Sentry.captureException(err)
      return history.push('/')
    }
  }

  const loadCustomQuestions = async ({ shortlink, code }) => {
    const params = {
      custom_question: {
        shortlink: shortlink,
        offer_code: code,
      },
    }

    try {
      const res = await Api.getCustomQuestions(params)

      if (!res.errors) {
        if (!res.question) {
          return
        }

        const data = {
          question: res.question[0],
          possibleAnswers: res.possibleAnswers,
        }
        setCustomQuestion(data)
      } else {
        throw res.errors
      }
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const loadTemplate = async (eventId) => {
    const params = {
      survey: {
        survey_owner_id: surveyState.speaker.id,
        event_id: eventId,
        event_group_id: surveyState.eventGroup.id,
      },
    }

    showFullPageLoader('', 'light')

    try {
      const res = await Api.fetchTemplate(params)

      if (!res.errors) {
        setSurvey(res)
        hideFullPageLoader()
        return
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return setNotification(JSON.stringify(err))
    }
  }

  const renderEventPlannerSurveyDetails = () => {
    if (surveyState.showEventGroupWelcomePage) {
      return <EventPlannerWelcomePage loadTemplate={loadTemplate} />
    }

    return <QuestionParent />
  }

  const renderSpeakerSurveyDetails = () => {
    if (surveyState.configurationLoaded) {
      return <QuestionParent />
    }

    return (
      <SpeakerWelcomePage
        loadSurveyConfiguration={loadSurveyConfiguration}
        loadCustomQuestions={loadCustomQuestions}
        shortlink={params?.shortlink}
      />
    )
  }

  const renderFooter = () => {
    if (
      !surveyState.configurationLoaded ||
      (question && question.questionOrder === 0)
    ) {
      return <SurveyFooter />
    }
  }

  const surveyHeight =
    isMobileOnly || screenHeight < SURVEY_HEIGHT_THRESHOLD
      ? '100%'
      : SURVEY_HEIGHT_THRESHOLD

  const renderSurvey = () => (
    <SurveyContainer
      isTestMode={Survey.isTestMode()}
      showBorder={!isMobileOnly}
      height={surveyHeight}>
      <SurveyHeader survey={surveyState} />
      {surveyState.speaker.isEventPlanner
        ? renderEventPlannerSurveyDetails()
        : renderSpeakerSurveyDetails()}
      {renderFooter()}
    </SurveyContainer>
  )

  return surveyState.id && renderSurvey()
}

export default SurveyParent
