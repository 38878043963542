import { exportComponentAsJPEG } from 'react-component-export-image';

export const handleCopy = (content) => {
  window.navigator.clipboard.writeText(content)
}

export const capitalizeFirstLetter = (string) => {
  if (!string || string === '') {
    return string
  }
  
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const downloadInvisibleComponent = (componentRef, componentId) => {
  return exportComponentAsJPEG(componentRef, {
    html2CanvasOptions: {
      onclone: (clonedDoc) => {
        clonedDoc.getElementById(componentId).style.visibility = "visible";
      },
    }
  })
}